import TrackingLink from 'components/02-molecules/TrackingLink';
import { getOPlusPageURL, getSignUpURL } from 'helpers/utils/accountsUrls';
import { useUserType } from 'helpers/hooks/useUserType';

import OutsidePlusSVG from '../../../public/outside-plus.svg';

type Props = {
  placement: Analytics['placement'];
};

const IncludedWithOPlus = ({ placement }: Props) => {
  const { isLoggedIn } = useUserType();

  return (
    <TrackingLink
      analytics={{
        name: 'Included with Outside+ indicator',
        placement
      }}
      classes="flex items-baseline w-full gap-1"
      href={isLoggedIn ? getOPlusPageURL(true) : getSignUpURL(true)}
      isExternal
      title="Included with Outside+"
    >
      <span className="text-primary text-14 font-semibold whitespace-nowrap">
        Included with
      </span>
      <span className="w-full max-w-[61px] h-3.5">
        <OutsidePlusSVG width="100%" />
      </span>
    </TrackingLink>
  );
};

export default IncludedWithOPlus;
