import dynamic from 'next/dynamic';
import { getArrayOfTags } from 'helpers/utils/media/getArrayOfTags';
import type { ReactNode } from 'react';
import PreviewCard from 'components/02-molecules/PreviewCard';
import { useTailwindBreakpoint } from 'helpers/hooks/useTailwindBreakpoint';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';

const DynamicPreview = dynamic(() => import('components/02-molecules/Preview'));

export type Eyebrow = {
  analyticsName: Analytics['name'];
  href: string;
  label: string;
};

type Props = Pick<
  MediaItem,
  'category' | 'description' | 'location' | 'mediaId' | 'tags' | 'title'
> & {
  additionalLabels: ReactNode[];
  analytics: Analytics;
  children: ReactNode;
  eyebrow?: Eyebrow;
  href: string;
  image: string;
  isHoverable?: boolean;
  isIncludedWithOPlus: boolean;
  mediaProgress?: MediaProgress;
  renderActionButton: (closeHover: VoidFunction) => JSX.Element;
};

const MediaPreview = ({
  additionalLabels,
  analytics,
  children,
  description,
  eyebrow,
  href,
  image,
  isHoverable,
  isIncludedWithOPlus,
  location,
  mediaId,
  mediaProgress,
  renderActionButton,
  tags,
  title
}: Props) => {
  const { isReady, isScreenNarrowerThan } = useTailwindBreakpoint();
  const isMobile = isScreenNarrowerThan('narrow');

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (isMobile && isReady) return <>{children}</>;

  return (
    <DynamicPreview
      animationKey={mediaId}
      isHoverable={isHoverable}
      renderContent={(closeHover, previewHeight) => (
        <PreviewCard
          actionButton={renderActionButton(closeHover)}
          additionalLabels={additionalLabels}
          analytics={analytics}
          description={description}
          eyebrow={eyebrow}
          href={href}
          image={image}
          isIncludedWithOPlus={isIncludedWithOPlus}
          location={location}
          mediaProgress={mediaProgress}
          previewHeight={previewHeight}
          tags={getArrayOfTags(tags, 3)}
          title={title}
        />
      )}
    >
      {children}
    </DynamicPreview>
  );
};

export default MediaPreview;
