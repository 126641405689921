import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { getOPlusInfo } from 'components/02-molecules/OPlusBadges/helpers';

import { formatSecondsToTime } from '../formatSecondsToTime';
import { createURL } from '../url/createURL';

import { generateMediaProgressFromUserProgress } from './generateMediaProgressFromUserProgress';
import { getCategoryEyebrow } from './getCategoryEyebrow';
import { getFilmDetailsUrl } from './getFilmDetailsUrl';
import { getMediaTitle } from './getMediaTitle';

export const getFilmPreviewDetails = (film: MediaItem) => {
  const {
    category,
    duration,
    filmDuration,
    filmMediaItem,
    mediaId,
    meterFlow,
    productionYear,
    title,
    userProgress
  } = film;

  const mediaProgress = generateMediaProgressFromUserProgress(userProgress);

  const href = getFilmDetailsUrl(title, mediaId);

  const actionButtonHref = createURL(href, {
    mode: 'fullscreen'
  });

  const eyebrow = category ? getCategoryEyebrow(category) : null;

  const mediaDuration = formatSecondsToTime(filmDuration ?? duration);

  const ctaTextButton = mediaProgress ? 'Resume' : null;

  const heading = getMediaTitle(title);

  const additionalLabels = [mediaDuration, productionYear];

  const subHeadingText = mediaDuration;

  const { isIncludedWithOPlus } = getOPlusInfo(
    filmMediaItem ? filmMediaItem.meterFlow : meterFlow
  );

  return {
    actionButtonHref,
    additionalLabels,
    category,
    ctaTextButton,
    eyebrow,
    heading,
    href,
    isIncludedWithOPlus,
    mediaProgress,
    subHeadingText
  };
};
