/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import type {
  Level as HeadingLevel,
  ResponsiveVariant as HeadingResponsiveVariant
} from 'components/01-atoms/Heading';
import Heading from 'components/01-atoms/Heading';
import { useState } from 'react';
import { useFluidElements } from 'helpers/hooks/useFluidElements';
import Link from 'next/link';
import type { ImageProps } from 'next/image';
import { removeNullableContent } from 'helpers/utils/media/removeNullableContent';

import type { DesktopViewLimit } from '../Carousel';
import Carousel from '../Carousel';
import ShortThumbnail from '../ShortThumbnail';
import { mapMediaItemToShortThumbnail } from '../ShortThumbnail/mappings';

import type {
  CarouselElementMap,
  MediaCarouselType,
  TileAction
} from './types';
import MediaCarouselPoster from './MediaCarouselPoster';
import MediaCarouselThumbnail from './MediaCarouselThumbnail';
import ContinueWatchingThumbnail from './ContinueWatchingThumbnail';
import { getCarouselProps } from './mappings';

const carouselElementMap: CarouselElementMap = {
  continueWatching: {
    element: ContinueWatchingThumbnail,
    mapper: getCarouselProps
  },
  poster: { element: MediaCarouselPoster, mapper: getCarouselProps },
  short: { element: ShortThumbnail, mapper: mapMediaItemToShortThumbnail },
  thumbnailPreview: {
    element: MediaCarouselThumbnail,
    mapper: getCarouselProps
  },
  thumbnailSimple: { element: ShortThumbnail, mapper: getCarouselProps }
};

export type Props = {
  // see: temp-trailers
  allowTrailers?: boolean;
  containerClasses?: string;
  containerId?: string;
  desktopViewLimit?: DesktopViewLimit;
  headingLevel?: HeadingLevel;
  headingResponsiveVariant?: HeadingResponsiveVariant;
  headingText?: string;
  isFluid?: boolean;
  items: MediaItem[];
  priority?: ImageProps['priority'];
  startIndex?: number;
  tileAction?: TileAction;
  type: MediaCarouselType;
  viewAllRoute?: string;
  withIndex?: boolean;
};

const MediaCarousel = ({
  allowTrailers,
  containerClasses,
  containerId,
  desktopViewLimit,
  headingLevel = 2,
  headingResponsiveVariant = 'medium',
  headingText,
  isFluid,
  items,
  priority,
  startIndex,
  tileAction = 'redirect',
  type,
  viewAllRoute,
  withIndex
}: Props) => {
  const { carouselViewLimit } = useFluidElements();
  const [isScrolling, setIsScrolling] = useState(false);

  const nonNullableItems = removeNullableContent(items);

  if (!nonNullableItems?.length) return null;

  const { element: CarouselElement, mapper: carouselElementMapper } =
    carouselElementMap[type];

  return (
    <div
      className={clsx(containerClasses, 'w-full')}
      {...(containerId ? { id: containerId } : {})}
    >
      <div>
        <div
          className={clsx('flex justify-between items-center w-full', {
            '-mb-5': withIndex,
            'mb-7': headingText
          })}
        >
          {headingText && (
            <Heading
              classes="text-network-white"
              level={headingLevel}
              responsiveVariant={headingResponsiveVariant}
              text={headingText}
            />
          )}
          {viewAllRoute && (
            <Link href={viewAllRoute}>
              <span className="text-white font-plus-jakarta-sans text-16">
                See All
              </span>
            </Link>
          )}
        </div>
        <Carousel
          desktopViewLimit={desktopViewLimit}
          isFluid={isFluid}
          setIsScrolling={setIsScrolling}
          startIndex={startIndex}
          withIndex={withIndex}
        >
          {nonNullableItems.map((mediaItem, index) => (
            <div key={mediaItem.mediaId}>
              <CarouselElement
                allowTrailers={allowTrailers}
                isCarouselScrolling={isScrolling}
                mapper={carouselElementMapper}
                mediaItem={mediaItem}
                placement={headingText}
                priority={priority && index < carouselViewLimit + 1}
                tileAction={tileAction}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default MediaCarousel;
