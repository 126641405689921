import { PLACEHOLDER_POSTER_SRC } from 'helpers/constants';

import { checkImageSourceValidity } from './checkImageSourceValidity';

const POSTER_WIDTH = 292;
const POSTER_RATIO = '3:4';

export const getCroppedPoster = (poster: string, thumbnail?: string) => {
  const isValidImageSource = checkImageSourceValidity(poster);
  if (!poster || !isValidImageSource) {
    if (thumbnail) return thumbnail;

    return PLACEHOLDER_POSTER_SRC;
  }

  return `${poster}?crop=${POSTER_RATIO}&width=${POSTER_WIDTH}`;
};
