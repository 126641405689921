import Button from 'components/02-molecules/Button';
import type { ButtonSize } from 'components/02-molecules/Button/helpers';
import Icon from 'components/01-atoms/Icon';

// When button is available (e.g. user has O+) - either simply provide link to redirect or do anything more than redirect on click
type ButtonAction =
  | { href: string; onClick?: never }
  | { href?: never; onClick: VoidFunction };

type Props = ButtonAction & {
  placement: Analytics['placement'];
  size?: ButtonSize;
  text?: string;
  title?: string;
  variant?: 'contained' | 'text';
};

const WatchCTAButton = ({
  href,
  onClick,
  placement,
  size,
  text,
  title,
  variant = 'contained'
}: Props) => {
  const hasOnClickAction = onClick && !href;

  return (
    <Button
      analytics={{
        name: 'Watch button',
        placement
      }}
      as={hasOnClickAction ? 'button' : 'link'}
      href={href}
      icon={<Icon type="play" />}
      onClick={onClick}
      size={size}
      text={text || 'Watch'}
      title={title}
      variant={variant}
    />
  );
};

export default WatchCTAButton;
