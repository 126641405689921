import { routes } from 'lib/routes';
import { getOPlusInfo } from 'components/02-molecules/OPlusBadges/helpers';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';

import { createURL } from '../url/createURL';

import { generateMediaProgressFromSeriesProgress } from './generateMediaProgressFromSeriesProgress';
import { getEpisodeEyebrow } from './getEpisodeEyebrow';
import { getWatchCTAButtonText } from './getWatchCTAButtonText';
import { getSeriesDuration } from './getSeriesDuration';

export const getEpisodePreviewDetails = (episode: MediaItem) => {
  const {
    description,
    episodeMeta,
    meterFlow,
    productionYear,
    seriesCount,
    seriesId,
    seriesProgress,
    seriesSlug,
    tags,
    title,
    trailerMediaItem
  } = episode;

  const mediaProgress = generateMediaProgressFromSeriesProgress(seriesProgress);

  const seriesUrl = routes.seriesDetail(seriesSlug, seriesId);
  const href = createURL(
    seriesUrl,
    episodeMeta
      ? {
          episode: `${episodeMeta.episode}`,
          mode: 'fullscreen',
          season: `${episodeMeta.season}`,
          ...(mediaProgress ? { timestamp: `${mediaProgress.timestamp}` } : {})
        }
      : null
  );

  const { isIncludedWithOPlus } = getOPlusInfo(meterFlow);

  const episodeTags = mediaProgress ? seriesProgress.episodeMedia.tags : tags;

  const eyebrow = mediaProgress
    ? getEpisodeEyebrow(trailerMediaItem?.title || title, seriesId)
    : null;

  const ctaTextButton = getWatchCTAButtonText(mediaProgress);

  return {
    actionButtonHref: href,
    additionalLabels: [getSeriesDuration(seriesCount), productionYear],
    ctaTextButton,
    eyebrow,
    heading: title,
    hoverTileHeading: title,
    href,
    isIncludedWithOPlus,
    mediaProgress,
    tags: episodeTags,
    textBlock: description
  };
};
