import { secondsToMilliseconds, intervalToDuration } from 'date-fns';

type Format = 'colon' | 'word';

const getWordFormat = (duration: Duration) => {
  const hours = duration.hours ? `${duration.hours}h ` : '';
  const minutes = duration.minutes
    ? `${duration.seconds >= 30 ? duration.minutes + 1 : duration.minutes}min`
    : '';
  const seconds =
    duration.seconds && !duration.minutes && !duration.hours
      ? `${duration.seconds}sec`
      : '';

  return `${hours}${minutes}${seconds}`.trim();
};

const getColonFormat = (duration: Duration) => {
  const hours = duration.hours ? `${duration.hours}:` : '';
  const minutes = duration.minutes ? `${duration.minutes}:` : '0:';
  const seconds = duration.seconds
    ? `${duration.seconds.toString().padStart(2, '0')}`
    : '00';

  return `${hours}${minutes}${seconds}`.trim();
};

export const formatSecondsToTime = (
  seconds: number,
  format: Format = 'word'
) => {
  if (!seconds) return null;

  const duration = intervalToDuration({
    end: secondsToMilliseconds(seconds),
    start: 0
  });

  switch (format) {
    case 'word':
      return getWordFormat(duration);
    case 'colon':
      return getColonFormat(duration);
    default:
      return null;
  }
};
