import FilmPoster from 'components/02-molecules/FilmPoster';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { getCroppedPoster } from 'helpers/utils/media/getCroppedPoster';
import MediaPreviewWrapper from 'components/03-organisms/MediaPreviewWrapper';

import type { MediaCarouselMapper } from './mappings';

type Props = {
  isCarouselScrolling: boolean;
  mapper: MediaCarouselMapper;
  mediaItem: MediaItem;
  placement: Analytics['placement'];
  priority?: boolean;
};

const MediaCarouselPoster = ({
  isCarouselScrolling,
  mapper,
  mediaItem,
  placement,
  priority
}: Props) => {
  const carouselItem = mediaItem.trailerMediaItem || mediaItem;

  const {
    analyticsName,
    heading,
    href,
    image,
    liveState,
    poster,
    subHeadingText
  } = mapper(carouselItem);

  const analytics = {
    name: analyticsName,
    placement
  };

  return (
    <MediaPreviewWrapper
      isCarouselScrolling={isCarouselScrolling}
      mediaItem={carouselItem}
      placement={placement}
    >
      <FilmPoster
        alt={heading}
        analytics={analytics}
        href={href}
        image={getCroppedPoster(poster, image)}
        liveState={liveState}
        priority={priority}
        subHeadingText={subHeadingText}
        text={heading}
      />
    </MediaPreviewWrapper>
  );
};

export default MediaCarouselPoster;
