import Image from 'next/image';
import HorizontalListWithDivider from 'components/01-atoms/HorizontalListWithDivider';
import clsx from 'clsx';
import Badge from 'components/01-atoms/Badge';
import Hoverable from 'components/02-molecules/Hoverable';
import type { Effect } from 'components/02-molecules/Hoverable';
import TrackingLink from 'components/02-molecules/TrackingLink';
import Heading from 'components/01-atoms/Heading';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import Flex from 'components/01-atoms/Flex';
import { useMountVideo } from 'helpers/hooks/useMountVideo';
import { useCallback } from 'react';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';

import type { CarouselMapperType, TileAction } from '../MediaCarousel/types';

import { mapMediaItemToShortThumbnail } from './mappings';

type Variant = 'column' | 'row';

export type Props = {
  // see: temp-trailers
  allowTrailers?: boolean;
  hoverEffects?: Effect[];
  hoverIconSize?: number;
  mapper?: CarouselMapperType;
  mediaItem: MediaItem;
  placement: Analytics['placement'];
  priority?: boolean;
  tileAction?: TileAction;
  variant?: Variant;
};

const ShortThumbnail = ({
  allowTrailers,
  hoverEffects,
  hoverIconSize,
  mapper = mapMediaItemToShortThumbnail,
  mediaItem,
  placement,
  priority,
  tileAction,
  variant = 'column'
}: Props) => {
  const {
    actionButtonHref,
    category,
    creator,
    duration,
    heading,
    href,
    image
  } = mapper(mediaItem, allowTrailers);

  const { elementClicked } = useAnalyticsContext();

  const { mountVideo } = useMountVideo();

  const openFullscreen = useCallback(() => {
    elementClicked({
      name: heading,
      placement,
      type: 'button'
    });
    mountVideo(mediaItem);
  }, [elementClicked, heading, mediaItem, mountVideo, placement]);

  const content = () => (
    <div
      className={clsx('grid', {
        'grid-cols-1 gap-y-3': variant === 'column',
        'grid-cols-2 gap-x-3': variant === 'row'
      })}
    >
      <div className="row-span-2">
        <Hoverable effects={hoverEffects} iconSize={hoverIconSize}>
          <div>
            <Image
              alt={heading}
              className="rounded"
              fill
              priority={priority}
              sizes="(max-width: 400px)"
              src={image}
            />
            {duration && (
              <Badge
                classes="absolute bottom-2 right-2"
                text={duration}
                variant="darkGray"
              />
            )}
          </div>
        </Hoverable>
      </div>
      <Flex className="flex-col gap-2">
        <Heading
          classes="text-network-white line-clamp-2"
          level={5}
          text={heading}
          variant="heading8"
        />
        <HorizontalListWithDivider
          classes="text-14"
          data={[category, creator]}
        />
      </Flex>
    </div>
  );

  if (tileAction === 'fullscreen') {
    return (
      <button
        className="w-full text-left"
        onClick={openFullscreen}
        type="button"
      >
        {content()}
      </button>
    );
  }

  return (
    <TrackingLink
      analytics={{
        name: 'Shorts detail page link',
        placement
      }}
      href={actionButtonHref || href}
      title={heading}
    >
      {content()}
    </TrackingLink>
  );
};

export default ShortThumbnail;
