import Image from 'next/image';
import HorizontalListWithDivider from 'components/01-atoms/HorizontalListWithDivider';
import Gradient from 'components/01-atoms/Gradient';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import type { ReactNode } from 'react';
import Heading from 'components/01-atoms/Heading';
import type { Eyebrow } from 'components/03-organisms/MediaPreview';

import MediaItemProgress from '../MediaItemProgress';
import Indicator from '../Indicator';
import Button from '../Button';
import TrackingLink from '../TrackingLink';
import OPlusBadges from '../OPlusBadges';

export type Props = Pick<
  MediaItem,
  'description' | 'location' | 'title' | 'userProgress'
> & {
  actionButton: JSX.Element;
  additionalLabels: ReactNode[] | null;
  analytics: Analytics;
  eyebrow?: Eyebrow;
  href: string;
  image: string;
  isIncludedWithOPlus: boolean;
  mediaProgress: MediaProgress;
  previewHeight: number;
  tags: ReactNode[] | null;
};

const PreviewCard = ({
  actionButton,
  additionalLabels,
  analytics,
  description,
  eyebrow,
  href,
  image,
  isIncludedWithOPlus,
  location,
  mediaProgress,
  previewHeight,
  tags,
  title
}: Props) => (
  <div
    className="hidden narrow-x:flex flex-col rounded overflow-hidden bg-neutral-800 text-white"
    style={{ minHeight: previewHeight }}
  >
    <TrackingLink
      analytics={{
        name: `${analytics.name} image`,
        placement: analytics.placement
      }}
      href={href}
      title={title}
    >
      <div className="w-full aspect-h-5 aspect-w-9 relative">
        <Image
          alt={`${title} poster`}
          fill
          sizes="(max-width: 360px)"
          src={image}
        />
        <Gradient direction="top" />
      </div>
    </TrackingLink>
    <div className="flex flex-col p-6 transform -translate-y-12 -mb-12">
      <div className="flex justify-between items-center mb-4">
        {actionButton}
        {additionalLabels && (
          <HorizontalListWithDivider
            classes="text-14"
            data={additionalLabels}
          />
        )}
      </div>
      {mediaProgress ? (
        <div className="-mt-3 mb-5">
          <MediaItemProgress
            label={mediaProgress.label}
            progress={!mediaProgress.isFinished ? mediaProgress.progress : 1}
          />
        </div>
      ) : null}
      <OPlusBadges
        className="mb-3"
        isIncludedWithOPlus={isIncludedWithOPlus}
        placement="Hover tile"
      />
      {eyebrow && (
        <Button
          analytics={
            eyebrow.analyticsName
              ? {
                  name: eyebrow.analyticsName,
                  placement: analytics.placement
                }
              : null
          }
          as="link"
          className="mb-2 capitalize font-semibold self-baseline !text-14"
          href={eyebrow.href}
          text={eyebrow.label}
          variant="text"
        />
      )}
      <TrackingLink
        analytics={{
          name: analytics.name,
          placement: analytics.placement
        }}
        classes="mb-4"
        href={href}
        title={title}
      >
        <Heading
          classes="font-bold line-clamp-2"
          level={3}
          text={title}
          variant="heading7"
        />
      </TrackingLink>
      {location && (
        <div className="mb-4">
          <Indicator type="location" value={location} />
        </div>
      )}
      {description && (
        <TrackingLink
          analytics={{
            name: `${analytics.name} description`,
            placement: analytics.placement
          }}
          href={href}
          title={title}
        >
          <p className="text-14 text-dark-gray mb-4 font-plus-jakarta-sans line-clamp-3">
            {description}
          </p>
        </TrackingLink>
      )}
      {tags && <HorizontalListWithDivider classes="text-12" data={tags} />}
    </div>
  </div>
);

export default PreviewCard;
