import { MeterFlow } from 'helpers/types/jwplayer-subgraph';

type Output = {
  isIncludedWithOPlus?: boolean;
};

export const getOPlusInfo = (meterflow: MeterFlow): Output => {
  const isIncludedWithOPlus = meterflow === MeterFlow.Locked;

  return {
    isIncludedWithOPlus
  };
};
