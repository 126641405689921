import { getMediaTitle } from './getMediaTitle';
import { getSeriesDetailsUrl } from './getSeriesDetailsUrl';

export const getEpisodeEyebrow = (
  seriesTitle: string,
  seriesMediaId: string
) => ({
  analyticsName: 'Series detail page link',
  href: getSeriesDetailsUrl(seriesTitle, seriesMediaId),
  label: getMediaTitle(seriesTitle)
});
