import Progress from 'components/01-atoms/Progress';
import Flex from 'components/01-atoms/Flex';
import clsx from 'clsx';

export type Props = {
  classes?: string;
  label: string;
  progress: number;
};

const MediaItemProgress = ({ classes, label, progress }: Props) => (
  <Flex className="items-center">
    <Progress
      classes={clsx('w-[130px]', classes)}
      value={progress}
      variant="secondary"
    />
    <div className="text-white font-plus-jakarta-sans text-12 ml-3">
      {label}
    </div>
  </Flex>
);

export default MediaItemProgress;
