import Icon from 'components/01-atoms/Icon';
import Button from 'components/02-molecules/Button';
import WatchCTAButton from 'components/03-organisms/WatchCTAButton';
import { EventState } from 'helpers/types/jwplayer-subgraph';

type Props = {
  actionButtonHref: string;
  analytics: Analytics;
  ctaTextButton: string;
  liveState?: EventState;
  title: string;
};

const ActionButton = ({
  actionButtonHref,
  analytics,
  ctaTextButton,
  liveState,
  title
}: Props) => {
  const isLiveEvent = Boolean(liveState);
  const isEventWithLiveStatus = isLiveEvent && liveState === EventState.Live;

  if (isEventWithLiveStatus || !isLiveEvent) {
    return (
      <WatchCTAButton
        href={actionButtonHref}
        placement={analytics.placement}
        size="sm"
        text={ctaTextButton}
        title={`Watch ${title}`}
      />
    );
  }

  return (
    <Button
      analytics={analytics}
      as="link"
      href={actionButtonHref}
      icon={<Icon className="text-black" type="infoWhite" width={20} />}
      size="sm"
      text="More Info"
      variant="contained"
    />
  );
};

export default ActionButton;
