import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { getOPlusInfo } from 'components/02-molecules/OPlusBadges/helpers';

import { createURL } from '../url/createURL';

import { generateMediaProgressFromSeriesProgress } from './generateMediaProgressFromSeriesProgress';
import { getCategoryEyebrow } from './getCategoryEyebrow';
import { getEpisodeEyebrow } from './getEpisodeEyebrow';
import { getEpisodeIndicatorLabel } from './getEpisodeIndicatorLabel';
import { getEpisodeTitleWithIndex } from './getEpisodeTitle';
import { getMediaTitle } from './getMediaTitle';
import { getSeriesDetailsUrl } from './getSeriesDetailsUrl';
import { getWatchCTAButtonText } from './getWatchCTAButtonText';

export const getSeriesPreviewDetails = (series: MediaItem) => {
  const {
    category,
    description,
    seriesId,
    seriesMeterflow,
    seriesProgress,
    tags,
    title
  } = series;

  const mediaProgress = generateMediaProgressFromSeriesProgress(seriesProgress);

  const href = mediaProgress
    ? createURL(getSeriesDetailsUrl(title, seriesId), {
        episode: `${seriesProgress.episode}`,
        season: `${seriesProgress.season}`
      })
    : getSeriesDetailsUrl(title, seriesId);

  const textBlock = mediaProgress
    ? seriesProgress.episodeMedia.description
    : description;

  const episodeEyebrow = mediaProgress
    ? getEpisodeEyebrow(title, seriesId)
    : null;

  const categoryEyebrow = category ? getCategoryEyebrow(category) : null;

  const eyebrow = episodeEyebrow || categoryEyebrow;

  const actionButtonHref = createURL(href, {
    mode: 'fullscreen',
    ...(mediaProgress ? { timestamp: `${mediaProgress.timestamp}` } : {})
  });

  const seriesTags = mediaProgress ? seriesProgress.episodeMedia.tags : tags;

  const heading = getMediaTitle(title);

  const altHeading = mediaProgress
    ? `${getEpisodeIndicatorLabel({
        episode: seriesProgress.episode,
        season: seriesProgress.season
      })}: ${getMediaTitle(title)}`
    : getMediaTitle(title);

  const hoverTileHeading = mediaProgress
    ? getEpisodeTitleWithIndex(
        seriesProgress.episode,
        seriesProgress.episodeMedia.title
      )
    : getMediaTitle(title);

  const ctaTextButton = getWatchCTAButtonText(mediaProgress);

  const { isIncludedWithOPlus } = getOPlusInfo(seriesMeterflow);

  return {
    actionButtonHref,
    altHeading,
    category,
    ctaTextButton,
    eyebrow,
    heading,
    hoverTileHeading,
    href,
    isIncludedWithOPlus,
    mediaProgress,
    tags: seriesTags,
    textBlock
  };
};
