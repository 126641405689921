import MediaPreview from 'components/03-organisms/MediaPreview';
import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import type { ReactNode } from 'react';

import { getCarouselProps } from '../MediaCarousel/mappings';
import ActionButton from '../ActionButton';

type Props = {
  children: ReactNode;
  isCarouselScrolling: boolean;
  mediaItem: MediaItem;
  placement: Analytics['placement'];
};

const MediaPreviewWrapper = ({
  children,
  isCarouselScrolling,
  mediaItem,
  placement
}: Props) => {
  const {
    actionButtonHref,
    additionalLabels,
    analyticsName,
    ctaTextButton,
    eyebrow,
    heading,
    hoverTileHeading,
    href,
    image,
    isIncludedWithOPlus,
    liveState,
    location,
    mediaId,
    mediaProgress,
    tags,
    textBlock
  } = getCarouselProps(mediaItem);

  const analytics = {
    name: analyticsName,
    placement
  };

  return (
    <MediaPreview
      additionalLabels={additionalLabels}
      analytics={analytics}
      description={textBlock}
      eyebrow={eyebrow}
      href={href}
      image={image}
      isHoverable={!isCarouselScrolling}
      isIncludedWithOPlus={isIncludedWithOPlus}
      location={location}
      mediaId={mediaId}
      mediaProgress={mediaProgress}
      renderActionButton={() => (
        <ActionButton
          actionButtonHref={actionButtonHref}
          analytics={analytics}
          ctaTextButton={ctaTextButton}
          liveState={liveState}
          title={heading}
        />
      )}
      tags={tags}
      title={hoverTileHeading || heading}
    >
      {children}
    </MediaPreview>
  );
};

export default MediaPreviewWrapper;
