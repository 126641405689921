import Image from 'next/image';
import Heading from 'components/01-atoms/Heading';
import { EventState } from 'helpers/types/jwplayer-subgraph';

import EventBadgeManager from '../EventBadgeManager';
import TrackingLink from '../TrackingLink';

export type FilmPosterProps = {
  /**
   * Image alt
   */
  alt: string;
  analytics: Analytics;
  /**
   * Classes to be applied to the outer div
   */
  classes?: string;
  href: string;
  /**
   * Image source
   */
  image: string;
  liveState?: EventState;
  priority?: boolean;
  subHeadingText?: string;
  /**
   * Configuration of title and link
   */
  text: string;
};

const FilmPoster = ({
  alt,
  analytics,
  classes = '',
  href,
  image,
  liveState,
  priority,
  subHeadingText,
  text
}: FilmPosterProps) => (
  <div className={classes}>
    <div className="shadow-md overflow-hidden relative">
      <TrackingLink analytics={analytics} href={href} title={`View ${text}`}>
        <div className="w-full aspect-h-4 aspect-w-3 overflow-hidden relative rounded">
          <Image
            alt={alt}
            className="w-full h-full object-cover"
            fill
            priority={priority}
            sizes="(max-width: 318px)"
            src={image}
          />
        </div>
        {liveState === EventState.Live && (
          <div className="absolute z-1 top-5 left-3">
            <EventBadgeManager state={EventState.Live} />
          </div>
        )}
        <div className="flex mt-3 narrow-x:mt-5 flex-col">
          <Heading
            classes="text-network-white"
            level={5}
            text={text}
            variant="heading8"
          />
          {subHeadingText && (
            <h6 className="font-plus-jakarta-sans font-light mt-3 text-dark-gray text-14 capitalize">
              {subHeadingText}
            </h6>
          )}
        </div>
      </TrackingLink>
    </div>
  </div>
);

export default FilmPoster;
