import type { VideoProgress } from 'helpers/types/jwplayer-subgraph';

export const getWatchCTAButtonText = (videoProgress: VideoProgress) => {
  /* Watch:
    - the user does not have any saved progress
    - the next episode is proposing with 0 timestamp */
  if (!videoProgress || (videoProgress && videoProgress.timestamp === 0))
    return 'Watch';
  /* Resume:
    - the user has saved progress */
  return 'Resume';
};
