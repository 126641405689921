import clsx from 'clsx';
import IncludedWithOPlus from 'components/01-atoms/IncludedWithOPlus';

type Props = {
  className?: string;
  isIncludedWithOPlus?: boolean;
  placement: Analytics['placement'];
};

const OPlusBadges = ({ className, isIncludedWithOPlus, placement }: Props) => {
  if (!isIncludedWithOPlus) return null;

  return (
    <div className={clsx('flex items-center gap-4 max-w-max', className)}>
      {isIncludedWithOPlus && <IncludedWithOPlus placement={placement} />}
    </div>
  );
};

export default OPlusBadges;
