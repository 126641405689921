import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { NavigationLink } from 'components/02-molecules/NavigationLink';
import clsx from 'clsx';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import { PLACEHOLDER_THUMBNAIL_SRC } from 'helpers/constants';
import MediaPreviewWrapper from 'components/03-organisms/MediaPreviewWrapper';

import type { MediaCarouselMapper } from './mappings';

type Props = {
  isCarouselScrolling: boolean;
  mapper: MediaCarouselMapper;
  mediaItem: MediaItem;
  placement: Analytics['placement'];
  priority?: ImageProps['priority'];
};

const MediaCarouselThumbnail = ({
  isCarouselScrolling,
  mapper,
  mediaItem,
  placement,
  priority
}: Props) => {
  const carouselItem = mediaItem.trailerMediaItem || mediaItem;

  const { heading, href, image } = mapper(carouselItem);

  return (
    <MediaPreviewWrapper
      isCarouselScrolling={isCarouselScrolling}
      mediaItem={carouselItem}
      placement={placement}
    >
      <div className="w-full relative transform rounded-lg z-40 hover:cursor-pointer">
        <NavigationLink href={href} label={heading}>
          <div
            className={clsx(
              'relative text-network-white text-sm font-plus-jakarta-sans'
            )}
          >
            <div className="aspect-h-9 aspect-w-16">
              <Image
                alt={`${heading} film poster`}
                className="h-full w-full"
                fill
                priority={priority}
                src={image || PLACEHOLDER_THUMBNAIL_SRC}
              />
            </div>
            <h3
              className={clsx(
                'text-network-white text-sm mt-4 font-plus-jakarta-sans text-14'
              )}
            >
              {heading}
            </h3>
          </div>
        </NavigationLink>
      </div>
    </MediaPreviewWrapper>
  );
};

export default MediaCarouselThumbnail;
