import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { formatSecondsToTime } from 'helpers/utils/formatSecondsToTime';
import { getShortDetailsUrl } from 'helpers/utils/media/getShortDetailsUrl';
import { PLACEHOLDER_THUMBNAIL_SRC } from 'helpers/constants';
import { getImageByWidth } from 'helpers/utils/getImageByWidth';

export const mapMediaItemToShortThumbnail = (mediaItem: MediaItem) => {
  const { category, creator, duration, images, mediaId, title } = mediaItem;

  return {
    ...mediaItem,
    actionButtonHref: null,
    category,
    creator,
    duration: formatSecondsToTime(duration, 'colon'),
    heading: title,
    href: getShortDetailsUrl(title, mediaId),
    id: mediaId,
    image: getImageByWidth(images, 320).src || PLACEHOLDER_THUMBNAIL_SRC
  };
};

export type ShortCarouselMapper = typeof mapMediaItemToShortThumbnail;
