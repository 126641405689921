type Input = {
  episode: number;
  season: number;
};

const Indicator = Intl.NumberFormat('en-US', {
  minimumIntegerDigits: 2,
  useGrouping: false
});

export const getEpisodeIndicatorLabel = ({ episode, season }: Input) => {
  const episodeIndicator = Indicator.format(episode);
  const seasonIndicator = Indicator.format(season);

  return `S${seasonIndicator} E${episodeIndicator}`;
};
