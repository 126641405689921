import clsx from 'clsx';

type Variant = 'primary' | 'secondary';

type Props = {
  classes?: string;
  value: number;
  variant: Variant;
};

const Percent = Intl.NumberFormat('en-US', {
  style: 'percent'
});

const Progress = ({ classes, value, variant }: Props) => {
  /* if episode tile do not display empty progress bar for those with 0 progress value */
  if (value === 0 && variant === 'primary') return null;

  return (
    <div
      className={clsx(classes, 'flex rounded-theme-border-rounded h-1', {
        'bg-neutral-700': variant === 'secondary',
        'bg-neutral-900': variant === 'primary'
      })}
    >
      <div
        className={clsx(
          'flex h-full rounded-theme-border-rounded transition-all duration-600',
          {
            'bg-btn-primary': variant === 'primary',
            'bg-white': variant === 'secondary'
          }
        )}
        style={{ width: Percent.format(value) }}
      />
    </div>
  );
};

export default Progress;
