// Custom Next Configuration.
const _ = require('lodash');
// eslint-disable-next-line import/order
const path = require('path');

const basePath =
  process.env.NODE_ENV === 'aws' ? process.env.CONTAINER_DIRECTORY : '';

module.exports = {
  basePath,
  env: {
    basePath
  },
  productionBrowserSourceMaps: true,
  webpack: (config) => {
    // Set theme alias first.
    // Then set _global as fallback.
    const webpackConfig = _.cloneDeep(config);

    if (!webpackConfig.resolve.alias) webpackConfig.resolve.alias = {};
    webpackConfig.resolve.alias.react = [
      path.resolve(__dirname, `node_modules/react`)
    ];
    webpackConfig.resolve.alias['@styles'] = [
      path.resolve(__dirname, `_sites/${process.env.SITE}/styles`)
    ];
    webpackConfig.resolve.alias['@root'] = [path.resolve(__dirname)];
    webpackConfig.module.rules.push({
      test: /\.svg$/,
      use: ['@svgr/webpack']
    });

    return webpackConfig;
  }
};
