import type { VideoProgress } from 'helpers/types/jwplayer-subgraph';

import { getTimesLeftLabel } from './getTimesLeftLabel';

type Overrides = {
  label: string;
};

export const generateMediaProgressFromUserProgress = (
  userProgress: VideoProgress,
  overrides?: Partial<Overrides>
) =>
  userProgress && !userProgress.isFinished
    ? {
        label: overrides?.label || getTimesLeftLabel(userProgress.timeLeft),
        ...userProgress
      }
    : null;
