import type {
  SeasonProgress,
  SeriesProgress
} from 'helpers/types/jwplayer-subgraph';

import { getEpisodeIndicatorLabel } from './getEpisodeIndicatorLabel';

export const generateMediaProgressFromSeriesProgress = (
  seriesProgress: SeasonProgress | SeriesProgress
) =>
  seriesProgress && !seriesProgress.episodeProgress.isFinished
    ? {
        label: getEpisodeIndicatorLabel({
          episode: seriesProgress.episode,
          season: seriesProgress.season
        }),
        ...seriesProgress.episodeProgress
      }
    : null;
