import type { MediaItem } from 'helpers/types/jwplayer-subgraph';
import { NavigationLink } from 'components/02-molecules/NavigationLink';
import Image from 'next/image';
import Heading from 'components/01-atoms/Heading';
import Progress from 'components/01-atoms/Progress';
import MediaPreviewWrapper from 'components/03-organisms/MediaPreviewWrapper';

import type { MediaCarouselMapper } from './mappings';

type Props = {
  isCarouselScrolling: boolean;
  mapper: MediaCarouselMapper;
  mediaItem: MediaItem;
  placement: Analytics['placement'];
  priority?: boolean;
};

const ContinueWatchingThumbnail = ({
  isCarouselScrolling,
  mapper,
  mediaItem,
  placement,
  priority
}: Props) => {
  const carouselItem = mediaItem.trailerMediaItem || mediaItem;

  const { heading, href, image, mediaProgress } = mapper(carouselItem);

  return (
    <MediaPreviewWrapper
      isCarouselScrolling={isCarouselScrolling}
      mediaItem={carouselItem}
      placement={placement}
    >
      <NavigationLink href={href} label={heading}>
        <div className="grid grid-cols-1 gap-y-3">
          <div className="row-span-2">
            <div className="group aspect-h-9 aspect-w-16 relative">
              <div className="rounded">
                <Image
                  alt={heading}
                  className="rounded"
                  fill
                  priority={priority}
                  sizes="(max-width: 400px)"
                  src={image}
                />
                {mediaProgress ? (
                  <Progress
                    classes="absolute bottom-0 left-0 w-full"
                    value={
                      !mediaProgress.isFinished ? mediaProgress.progress : 1
                    }
                    variant="primary"
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <Heading
              classes="text-network-white"
              level={5}
              text={heading}
              variant="heading8"
            />
          </div>
        </div>
      </NavigationLink>
    </MediaPreviewWrapper>
  );
};

export default ContinueWatchingThumbnail;
